import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  AppBar,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Close from "@mui/icons-material/Close";
import { ArrowOutward } from "@mui/icons-material";
import { ThemeColors } from "../../../styles/theme";
import TableCheckbox from "../../../components/GenericListingScreen/ListingGrid/Checkbox";
import { useState } from "react";

export default NiceModal.create(({ onCancel, onProceed }: { onCancel: () => void, onProceed: () => void }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  // Use a hook to manage the modal state
  const modal = useModal();

  const handleCancel = async (e: unknown, reason?: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    onCancel?.();
    await modal.hide();
    modal.remove();
  };


  const handleProceed = async () => {
    onProceed();
    await modal.hide();
    modal.remove();
  };

  if (!modal.visible) return null;
  return (
    <Dialog fullScreen open onClose={handleCancel}>
      <AppBar sx={{ position: "relative" }} elevation={0} color="transparent">
        <Toolbar>
          <IconButton edge="start" onClick={handleCancel} aria-label="close">
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            &nbsp;
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography
            color="inherit"
            sx={{
              fontSize: 36,
              lineHeight: "44px",
              fontFamily: "Inter",
              fontWeight: 600,
              my: 3,
            }}
          >
            Your check-in is complete.
          </Typography>
          <Typography sx={{ pb: 10 }}>
            We'll notify you once the check-in is processed. Typically, this
            process can range from 5 minutes to an hour, although in some cases,
            it may take up to 48 hours.
          </Typography>
          <Box
            flex={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              pb: 4,
            }}
          >
            <Box sx={{ background: ThemeColors.white, borderRadius: 1 }}>
              <FormControlLabel
                control={<TableCheckbox checked={isConfirmed} onChange={()=>setIsConfirmed(!isConfirmed)} />}
                sx={{
                  my: 2,
                  width: "100%",
                  mx: 0,
                  borderRadius: 2,
                  p: 1,
                  border: `2px solid ${ThemeColors.primaryOrange}`,
                }}
                label={
                  <Box>
                    <Typography>
                      <strong>I confirm that:</strong>
                    </Typography>
                    <Typography>
                      I have inspected the property and consent to make it my
                      new residence.
                    </Typography>
                  </Box>
                }
              />
              <Button
                endIcon={<ArrowOutward />}
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleProceed}
                disabled={!isConfirmed}
              >
                Continue to Sign lease
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
});
