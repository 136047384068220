import Grid from "@mui/material/Grid";
import React from "react";
import ResponsiveGridSpacer from "../../../components/ResponsiveGridSpacer";
import TextField from "../../../components/TextField";
import { useFormContext } from "react-hook-form";
import RoomSelect from "../../../components/RoomSelect";
import { useParams } from "react-router-dom";
import { CheckInFlowStep } from "../CheckInFlow/types";
import Button from "@mui/material/Button";
import NiceModal from "@ebay/nice-modal-react";
import WizardCheckListModal from "../WizardCheckListModal";

const ResponsiveGridItem = ({ children }: { children: React.ReactNode }) => (
  <Grid container item xs={12}>
    <ResponsiveGridSpacer width={3} />
    <Grid item xs={12} md={6} sx={{ mt: 2 }}>
      {children}
    </Grid>
  </Grid>
);

const InitialForm: CheckInFlowStep = ({ onNextStep }) => {
  const { register, handleSubmit } = useFormContext();
  const { id: checkInId } = useParams<{ id: string }>();

  const showStatusModal = () => {
    NiceModal.show(WizardCheckListModal, {
      onConfirm: () => {
        onNextStep();
      },
      onCancel: () => {
        console.log("cancel");
      },
    });
  };

  const onSubmit = handleSubmit((data) => showStatusModal());

  return (
    <Grid container>
      <ResponsiveGridItem>
        <TextField
          label="Room name/number"
          type="text"
          placeholder="001"
          {...register("roomName", { required: true })}
        />
      </ResponsiveGridItem>
      <ResponsiveGridItem>
        {checkInId && (
          <RoomSelect
            checkInId={checkInId}
            label="Room type"
            {...register("roomType", {
              // required: true // TODO: uncomment this line
            })}
          />
        )}
      </ResponsiveGridItem>
      <ResponsiveGridItem>
        <TextField
          label="Number Of Beds In the Room"
          type="number"
          placeholder="12"
          {...register("NumberOfBeds", { required: true })}
        />
      </ResponsiveGridItem>
      {/* <ResponsiveGridItem>
        <TextField
          label="Floor number"
          type="number"
          inputProps={{ step: 1 }}
          placeholder="10"
          {...register("floorNumber")}
        />
      </ResponsiveGridItem> */}
      <ResponsiveGridItem>
        <Button variant="contained" fullWidth onClick={onSubmit}>
          Get Started
        </Button>
      </ResponsiveGridItem>
    </Grid>
  );
};

export default InitialForm;
