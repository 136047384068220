import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ThemeColors } from "../../../styles/theme";
import {
  ArrowBack,
  ArrowForward,
  Close,
  KeyboardArrowDown,
  MoreVert,
  Search,
} from "@mui/icons-material";
import {
  DataGrid,
  gridPageSelector,
  gridPageSizeSelector,
  GridRowSelectionModel,
  GridRowsProp,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { PAGE_SIZE } from "../../../constants";
import { TBulkActionFilter, TListingGridProps } from "./types";
import { css } from "@emotion/css";
import Checkbox from "./Checkbox";
import PopupState, { bindMenu, bindToggle } from "material-ui-popup-state";
import TextField from "../../TextField";
import NoItemsPaper from "../NoItemsPaper";
import { useSearchParams } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";

const NextButton = () => (
  <>
    Next <ArrowForward />{" "}
  </>
);

const PrevButton = () => (
  <>
    <ArrowBack /> Previous{" "}
  </>
);

const EMPTY_OPTION: TBulkActionFilter["options"] = [];

const getPaginationComponents = (count: number) => () => {
  const apiRef = useGridApiContext();
  const gridPage = useGridSelector(apiRef, gridPageSelector);
  const gridSize = useGridSelector(apiRef, gridPageSizeSelector);

  return (
    <Box py={3} px={2} display="flex" key={count} justifyContent="stretch">
      <Pagination
        count={Math.ceil(count / gridSize)}
        page={gridPage + 1}
        size="large"
        shape="rounded"
        variant="outlined"
        sx={{
          width: "100%",
          "& ul": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
          },
          "& li:first-of-type ": {
            flex: 1,
          },
          "  & li:last-of-type": {
            flex: 1,
            textAlign: "right",
          },
        }}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        renderItem={(item) => {
          return (
            <PaginationItem
              slots={{ previous: PrevButton, next: NextButton }}
              {...item}
              size="medium"
            />
          );
        }}
      />
    </Box>
  );
};

function ListingGrid({
  title,
  actionButtons = [],
  columns,
  dataFetchFn,
  bulkActionsFilter,
  onBulkAction,
  searchedText = "",
  activeFilters,
  hideActionButtons = false,
  noResultComponent,
}: TListingGridProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get("page")
    ? parseInt(searchParams.get("page") as string)
    : 1;

  const [paginationModel, setPaginationModel] = useState({
    page: initialPage - 1,
    pageSize: PAGE_SIZE,
  });
  const [{ rows, rowCount }, setRowState] = useState<{
    rows: GridRowsProp;
    rowCount: number;
  }>({ rows: [], rowCount: 0 });
  const [{loading, hasError, errorMessage }, setLoading] = useState({loading: true, hasError: false, errorMessage: ''});

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    if (isAllSelected) {
      setRowSelectionModel(rows.map((row) => row.id));
    }
  }, [isAllSelected, rows]);

  useEffect(() => {
    if (isAllSelected && rowSelectionModel.length !== rows.length) {
      setIsAllSelected(false);
    }
  }, [rowSelectionModel]);

  const moddedColumns = columns.map((column) => {
    return {
      ...column,
      sortable: false,
      filterable: false,
      resizable: false,
      hideable: false,
      disableColumnMenu: true,
      headerClassName: css({
        background: `${ThemeColors.disabled} !important`,
      }),
      cellClassName: css({
        color: `${ThemeColors.textTertiary} !important`,
      }),
    };
  });

  const [selectedBulkFilterActions, setSelectedBulkFilterActions] =
    useState<TBulkActionFilter["options"]>(EMPTY_OPTION);

  const activeRef = useRef(false);

  const getApiData = async () => {
    setLoading({hasError: false, loading: true, errorMessage: ''});
    const newRows = await dataFetchFn({
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      bulkActionFilter: {
        id: selectedBulkFilterActions[0]?.value,
        value: selectedBulkFilterActions[0]?.label,
      },
      searchedText,
      activeFilters,
    });

    if (!activeRef.current) {
      return;
    }
    if (newRows.hasError) {
      console.error("Error fetching grid data");
      setLoading({hasError: true, loading: false, errorMessage: newRows.errorMessage || ''});
      return;
    }
    setRowState({
      rows: newRows.data,
      rowCount: newRows.totalCount,
    });
    if (isAllSelected) {
      setIsAllSelected(true);
    }
    setSearchParams({
      page: searchedText ? "1" : `${paginationModel.page + 1}`,
    });
    setLoading({hasError: false, loading: false, errorMessage: ''});
  };

  React.useEffect(() => {
    activeRef.current = true;
    getApiData();
    return () => {
      activeRef.current = false;
    };
  }, [paginationModel.page, selectedBulkFilterActions, searchedText]);

  useDeepCompareEffect(() => {
    activeRef.current = true;
    getApiData();
    return () => {
      activeRef.current = false;
    };
  }, [activeFilters]);

  const hasCustomNoResultComponent = Boolean(noResultComponent);

  return (
    <Paper
      sx={{ width: "100%", overflow: "hidden", py: 2, mb: 3, borderRadius: 2 }}
    >
      <Box display="flex" alignItems="center">
        <Box flex={1} sx={{ px: 2 }}>
          {title && (
            <Typography
              color={ThemeColors.textPrimary}
              fontSize={18}
              fontWeight={600}
              gutterBottom
            >
              {title}
            </Typography>
          )}
        </Box>
        <Box sx={{ pb: 2, display: "inline-flex", alignItems: "center" }}>
          {actionButtons.map((button) => (
            <Button
              key={button.label}
              variant="outlined"
              color="primary"
              onClick={button.onClick}
              sx={{ ml: 1 }}
              size="small"
            >
              {button.label}
            </Button>
          ))}
          {!hideActionButtons && (
            <Grid
              container
              display="inline-flex"
              alignItems="end"
              sx={{ width: { md: 480, sx: 300 } }}
            >
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  "& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root":
                    {
                      flexWrap: "nowrap",
                      overflowX: "hidden",
                    },
                }}
              >
                <Autocomplete
                  sx={{ mr: 1 }}
                  value={selectedBulkFilterActions}
                  options={bulkActionsFilter?.options || []}
                  size="small"
                  multiple
                  clearIcon={<></>}
                  getOptionLabel={(option) => (option ? option.label : "")}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      setSelectedBulkFilterActions([]);
                    }
                    if (!selectedBulkFilterActions) {
                      return newValue;
                    }
                    const replacement = newValue.find(
                      (el) => selectedBulkFilterActions[0]?.label !== el.label
                    );
                    setSelectedBulkFilterActions(
                      replacement ? [replacement] : EMPTY_OPTION
                    );
                  }}
                  filterSelectedOptions
                  renderTags={(value) =>
                    value.map((option) => {
                      if (!option) {
                        return null;
                      }
                      return (
                        <Chip
                          variant="outlined"
                          label={option.label}
                          key={option.label}
                          size="small"
                          sx={{ borderRadius: 1, py: 0 }}
                          deleteIcon={<Close />}
                          onDelete={() =>
                            setSelectedBulkFilterActions(EMPTY_OPTION)
                          }
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>{" "}
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                      label={bulkActionsFilter?.label}
                      placeholder={
                        selectedBulkFilterActions[0] ? "" : "Find a status"
                      }
                      sx={{ width: 240 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                  {(popupState) => (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        fullWidth
                        disabled={
                          !selectedBulkFilterActions[0] ||
                          selectedBulkFilterActions[0].actions.length === 0
                        }
                        sx={{
                          height: 40,
                          minHeight: 0,
                          borderColor: "#D0D5DD",
                          borderRadius: 2,
                          fontSize: 14,
                        }}
                        endIcon={<KeyboardArrowDown />}
                        {...bindToggle(popupState)}
                      >
                        Select an option
                      </Button>
                      {selectedBulkFilterActions[0] && (
                        <Menu {...bindMenu(popupState)}>
                          {selectedBulkFilterActions[0].actions.map((el) => (
                            <MenuItem
                              onClick={() => {
                                onBulkAction?.(
                                  el.request,
                                  isAllSelected
                                    ? "ALL"
                                    : (rowSelectionModel as string[]),
                                  {
                                    id: selectedBulkFilterActions[0]?.value,
                                    value: selectedBulkFilterActions[0]?.label,
                                  },
                                  activeFilters
                                );
                                popupState.close();
                              }}
                              value={el.request}
                              sx={{ minWidth: 200 }}
                            >
                              {el.label}
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    </>
                  )}
                </PopupState>
              </Grid>
            </Grid>
          )}
          {!hideActionButtons && (
            <Grid
              container
              display="inline-flex"
              alignItems="end"
              sx={{ width: { md: 480, sx: 300 } }}
            >
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  "& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root":
                    {
                      flexWrap: "nowrap",
                      overflowX: "hidden",
                    },
                }}
              >
                <Autocomplete
                  sx={{ mr: 1 }}
                  value={selectedBulkFilterActions}
                  options={bulkActionsFilter?.options || []}
                  size="small"
                  multiple
                  clearIcon={<></>}
                  getOptionLabel={(option) => (option ? option.label : "")}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      setSelectedBulkFilterActions([]);
                    }
                    if (!selectedBulkFilterActions) {
                      return newValue;
                    }
                    const replacement = newValue.find(
                      (el) => selectedBulkFilterActions[0]?.label !== el.label
                    );
                    setSelectedBulkFilterActions(
                      replacement ? [replacement] : EMPTY_OPTION
                    );
                  }}
                  filterSelectedOptions
                  renderTags={(value) =>
                    value.map((option) => {
                      if (!option) {
                        return null;
                      }
                      return (
                        <Chip
                          variant="outlined"
                          label={option.label}
                          key={option.label}
                          size="small"
                          sx={{ borderRadius: 1, py: 0 }}
                          deleteIcon={<Close />}
                          onDelete={() =>
                            setSelectedBulkFilterActions(EMPTY_OPTION)
                          }
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>{" "}
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                      label={bulkActionsFilter?.label}
                      placeholder={
                        selectedBulkFilterActions[0] ? "" : "Find a status"
                      }
                      sx={{ width: 240 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                  {(popupState) => (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        fullWidth
                        disabled={
                          !selectedBulkFilterActions[0] ||
                          selectedBulkFilterActions[0].actions.length === 0
                        }
                        sx={{
                          height: 40,
                          minHeight: 0,
                          borderColor: "#D0D5DD",
                          borderRadius: 2,
                          fontSize: 14,
                        }}
                        endIcon={<KeyboardArrowDown />}
                        {...bindToggle(popupState)}
                      >
                        Select an option
                      </Button>
                      {selectedBulkFilterActions[0] && (
                        <Menu {...bindMenu(popupState)}>
                          {selectedBulkFilterActions[0].actions.map((el) => (
                            <MenuItem
                              onClick={() => {
                                onBulkAction?.(
                                  el.request,
                                  isAllSelected
                                    ? "ALL"
                                    : (rowSelectionModel as string[]),
                                  {
                                    id: selectedBulkFilterActions[0]?.value,
                                    value: selectedBulkFilterActions[0]?.label,
                                  },
                                  activeFilters
                                );
                                popupState.close();
                              }}
                              value={el.request}
                              sx={{ minWidth: 200 }}
                            >
                              {el.label}
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    </>
                  )}
                </PopupState>
              </Grid>
            </Grid>
          )}
          <IconButton sx={{ ml: 1 }}>
            <MoreVert />
          </IconButton>
        </Box>
      </Box>
      {rowSelectionModel.length > 0 && (
        <Box
          sx={{
            background: ThemeColors.bgPrimary,
            p: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: 14,
                lineHeight: "20px",
                fontWeight: 700,
                color: ThemeColors.textSecondary,
                px: 2,
              }}
            >
              You&apos;ve selected{" "}
              {isAllSelected ? rowCount : rowSelectionModel.length} items
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
            <Link
              component="button"
              onClick={() => {
                setIsAllSelected(true);
              }}
            >
              Select all {rowCount} items that match this status
            </Link>
          </Box>
        </Box>
      )}
      <TableContainer sx={{display: 'grid', minHeight: 300}} >
        <DataGrid
          sx={{
            minWidth: {md: 640, xs: 320},
            border: 0,
          }}
          columns={  moddedColumns}
          rows={rows}
          checkboxSelection
          paginationModel={paginationModel}
          pageSizeOptions={[PAGE_SIZE]}
          rowCount={rowCount}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          loading={loading}
          keepNonExistentRowsSelected
          disableRowSelectionOnClick
          slots={{
            columnHeaders: hasCustomNoResultComponent  && rows.length === 0 ? () => <></> : undefined,
            footer: hasCustomNoResultComponent && rows.length === 0 ?()=> <></>: getPaginationComponents(rowCount),
            baseCheckbox: Checkbox,
            noRowsOverlay: hasCustomNoResultComponent ? () => (
              <Stack height="100%" sx={{minHeight: 200 }} alignItems="center" justifyContent="center">
                {hasError ? <div>{errorMessage || 'Error getting data'}</div> : noResultComponent}
              </Stack>
            ): undefined,
          }}


          pagination
        />
      </TableContainer>
    </Paper>
  );
}

export default ListingGrid;
