import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ViewStudentProfilePage from "../ViewStudentProfile";
import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";

export default NiceModal.create(
  ({
    onCancel,
    onConfirm,
    studentId,
    entityName,
  }: {
    studentId: string;
    entityName: 'Tenant'; // TODO: add the other entity names
    onCancel: () => void;
    onConfirm: () => void;
  }) => {
    const modal = useModal();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleCancel = async (e: unknown, reason?: string) => {
      if (reason && reason === "backdropClick") {
        return;
      }
      onCancel?.();
      await modal.hide();
      modal.remove();
    };

    const handleConfirm = async () => {
      onConfirm();
      await modal.hide();
      modal.remove();
    };

    if (!modal.visible) return null;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={modal.visible}
        onClose={handleCancel}
        maxWidth="xl"
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1, textAlign: "center", width: '80vw' }}>Student Details</Box>
          <Box>
            <IconButton onClick={handleCancel}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ViewStudentProfilePage onCloseModal={handleCancel as any} entityName={entityName} displayMode="modal" studentId={studentId} />
        </DialogContent>
      </Dialog>
    );
  }
);