//import { getSubdomain } from './functions';
export const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const STORAGE = "nsfas";
export const NAVIGATIONS = "dashboardNavigations";
export const APP_URL = window.location.origin;

export const URLS = {
  HOME: "/",
  LOGIN: "/login",
  ENTRY_TYPE: "/entry-type",
  REGISTRATION: "/registration",
  DASHBOARD: "/dashboard",
  ADD_RESIDENCE: "/add-residence",
  STUDENT_REG: "/student-registration",
  ADD_ROOM: "/add-room",
  RESIDENCE_SUMMERY: "/residence-summery",
  DESCRIBE_ROOMS: "/describe-rooms",
  MORE_ABOUT_PROPERTY: "/more-about-property",
  UPLOAD_PROPERTY_IMAGES: "/upload-property-images",
  UPLOAD_PROPERTY_DOCUMENTS: "/upload-property-documents",
  PAYMENT_INFO: "/payment-info",
  PAYMENT: "/payment",
  CREATE_CASE: "/create-case/:id",
  SUCCESS_PAGE: "/success",
  CANCEL_PAGE: "/cancel",
  NOTIFY_PAGE: "/notify",
  MAKE_PAYMENT: "/make-payment/:id",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  REGISTRATION_SUCCESSFUL: "/registration-successful",
  UPDATE_PROFILE: "/update-profile",
  OPEN_CASES: "/open-cases/:id/:type",
  OPEN_CASE_DETAILS: "/open-cases-details/:id",
  LEASES: "/leases/:id/:type",
  RESIDENCE_DETAILS: "/residence-details/:id",
  // TENANTS: '/tenants/:id',
  TENANTS: "/tenants",
  TENANTS_DETAILS: "/tenants-details/:id",
  INSTITUTION: "/institution",
  INSTITUTION_UPLOAD: "/institution-upload",
  INSTITUTION_VIEW: "/institution-view",
  INSTITUTION_VIEW_LIST: "/institution-view-list",
  INSTITUTION_UPLOAD_STATUS: "/institution-upload-status",
  INSTITUTION_UPLOAD_LIST: "/institution-upload-list",
  NOT_FOUND: "/not-found",
  TEST_PAGE: "/security-check",
  UPDATE_DETAILS: "/update-details",
  SECURITY_PAGE: "/security-page",
  REDIRECT_PAGE: "/reg-student",
  PROCEED_TO_LOGIN: "/proceed-to-login",
  RESIDENCE_APPLICATIONS: "/residence-applications/:id",
  RESIDENCES: "/residences",
  INVOICES: "/invoices",
  CASES: "/cases",
  CREATE_CASE_TEMPORARY: "/cases/create",
  CASES_DETAILS: "/cases/:id",
  OFFER_LETTER: "/offer-letter",
  WORK_SPACE: "/work-space",
  PAYMENT_OPTIONS: "/payment-options",
  PAYMENT_OPTIONS_GATE: "/payment-options-gate/:id",
  PASSWORD_RESET_SUCCESSFUL: "/successful-reset",
  STUDENT_LISTING_VIEW: "/student-list",
  STUDENT__VIEW_ACTION: "/student-action",
  INSTITUTION_DASHBOARD: "/institution-dashboard",
  STUDENT_ACTION_VIEW: "/student-action/:id",
  CAMPUS_DETAILS: "/campus-details",
  USER_LIST: "/user-list",
  // USER_DETAILS:'/user-details',
  CREATE_USER: "/create-user",
  ADD_CAMPUS: "/add-campus",
  VIEW_PROPERTIES: "/view-properties/:id",
  VIEW_USER_DETAILS: "/user-details/:id",
  ADD_STUDENT: "/add-student",
  // CAMPUS_DETAILS:'/campus-details',
  VIEW_CAMPUS_DETAILS: "campus-details/:id",
  REGISTER_STUDENT: "/student-reg",
  SUCCESSFUL_STUDENT_REGISTRATION: "/successful-student-registration",

  STUDENT: {
    BASE: "/student",
    ROOT: "/student/*",
    DASHBOARD: "/",
    LIST: "/my-residence",
    SEARCH_RESIDENCE: "/search-residence",
    ACCOMODATION_DETAILS: "/accomodation-details/:id",
    MY_APPLICATIONS: "/my-applications/:type",
    MY_APPLICATION_DETAILS: "/my-application-details/:id",
    MY_RESIDENCES: "/my-residences",
    MY_PROFILE: "/my-profile",
    MY_RESIDENCE_DETAILS: "/my-residence-details/:id",
    MY_CASES: "/my-cases",
    MY_CASES_DETAILS: "/my-cases-details/:id",
    NOT_FOUND: "/not-found",
    UPDATE_PROFILE_TWO: "/my-profile",
    MY_PROPERTY: "/my-property/:id",
    CREATE_CASE: "/create-case",
    CHECK_IN_LISTING: "/student/checkin",
    CHECK_IN_DETAILS: "/student/checkin/:id",
    RESIDENCE_LISTING: "/student/residences",
    EDIT_PROFILE: "/:role/edit-profile",
  },

  AP: {
    BASE: "/ap",
    TENANTS: "/ap/tenants",
    EDIT_PROFILE: "/:role/edit-profile/:section",
    STUDENT_SEARCH: "/ap/students/search",
    SECURITY_QUESTIONS: "/ap/students/:id/security",
    OTP: "/ap/students/:id/otp",
    STUDENT_DETAILS: "/ap/:collection/:id",
    INVITE_STUDENT: "/ap/students/:id/invite",
    ACCOMODATION_APPLICATIONS: "/ap/accomodation-applications/:id",
  },

  UPDATE_PROFILE_SECTIONS: {
    details: "details",
    addresses: "addresses",
    documents: "documents",
    banking: "banking",
  },
};

export const API_URL = {
  COMMON: {
    API: `${API_BASE_URL}`,
    GET_INSTITUTIONS: `${API_BASE_URL}/Customer/lookup`,
    GET_CAMPUS: `${API_BASE_URL}/Campus/lookup`,
    //
    GET_CAMPUS_INSTITUTION: `${API_ROOT_URL}/v1/Entities/Data/GetCampusInstitution`,
    GET_CAPACITIES: `${API_BASE_URL}/Amenity/lookup`,
    RETREIVE: `${API_ROOT_URL}/v1/entities/LazyRetrieveMultiple`,
    RETREIVE_INSTITUTION: `${API_ROOT_URL}/v1/entities/Customer/lookup`,
    GET_ROOMS: `${API_BASE_URL}/Rooms/lookup`,
    GET_STUDENT_CASES_CLASSIFICATION: `${API_ROOT_URL}/v1/entities/CaseClassification/lookup`,
    RETREIVE_CASE_DETAILS: `${API_ROOT_URL}/v1/Entities/Data/RetrieveCaseDetails`,
    RETREIVE_STUDENT_CASE_REGARDINGID: `${API_ROOT_URL}/v1/Entities/Data/RetrieveStudentCaseRegardingId`,
    GET_IMPORT_TYPE: `${API_ROOT_URL}/v1/Entities/Data/NsfasImportTemplates`,
    //
    GET_BANKS: `${API_ROOT_URL}/v1/Entities/Data/GetBanks`,
    GET_FACILITY_PRICING: `${API_ROOT_URL}/v1/Entities/Data/GetFacilityPricing`,
    GET_FACILITY_APPLICATION_DETAILS: `${API_ROOT_URL}/v1/Entities/Data/GetFacilityApplicationDetails`,
    GET_FACILITY_APPLICATIONS: `${API_ROOT_URL}/v1/Entities/Data/GetFacilityApplications`,
    GET_APPLICATION_ROOM_TYPES: `${API_ROOT_URL}/v1/Entities/Data/GetApplicationRoomTypes`,
    GET_APPLICATION_DATES: `${API_ROOT_URL}/v1/Entities/Data/RetrieveApplicationDatesReq`,
    GET_AP_OFFER_LETTERS: `${API_ROOT_URL}/v1/Entities/Data/GetAPOfferLetters`,
    GET_INSTITUTION_STUDENTS: `${API_ROOT_URL}/v1/Entities/Data/GetInstitutionStudents`,
    GET_STUDENT_DETAILS: `${API_ROOT_URL}/v1/Entities/Data/GetStudentDetails`,
    GET_CAMPUS_STATS: `${API_ROOT_URL}/v1/Entities/Data/GetCampusStats`,
    GET_INSTITUTION_TOTALS: `${API_ROOT_URL}/v1/Entities/Data/GetInstitutionTotals`,
    GET_CAMPUS_DETAILS: `${API_ROOT_URL}/v1/Entities/Data/GetCampusDetails`,
    GET_CAMPUS_PROPERTIES: `${API_ROOT_URL}/v1/Entities/Data/GetCampusProperties`,
    GET_AP_USERS: `${API_ROOT_URL}/v1/Entities/Data/GetAPUsers`,
    GET_INDIVIDUAL_AP_USERS: `${API_ROOT_URL}/v1/Entities/Data/GetIndividualAPUsers`,
    GET_ROOM_TYPES: `${API_ROOT_URL}/v1/Entities/Data/GetRoomTypes`,
    EXISTING_STUDENT_CHECK: `${API_ROOT_URL}/v1/Entities/Data/ExistingStudentCheck`,

    // GET_FACILITY_APPLICATIONS:`${API_ROOT_URL}/v1/Entities/Data/GetFacilityApplications`
  },
  USER: {
    LOGIN: `${API_BASE_URL}/ExecuteRequest`,
    ME: `${API_BASE_URL}details`,
    SOCIAL_LOGIN: `${API_BASE_URL}customer/social-login`,
    INSTAGRAM_DATA: `${API_BASE_URL}instagram-data`,
    REGISTER: `${API_BASE_URL}register`,
    FORGOT_PASSWORD: `${API_BASE_URL}send-reset-email`,
    RESET_PASSWORD: `${API_BASE_URL}save-password`,
    EDIT_PROFILE: `${API_BASE_URL}profile-update`,
    EDIT_PREFERENCE: `${API_BASE_URL}customer-preferencess-submit`,
    EDIT_USER_SETTINGS: `${API_BASE_URL}profile-settings-submit`,
    UPDATE_PROFILE_PIC: `${API_BASE_URL}profile-pic-update`,
    REMOVE_PROFILE_PIC: `${API_BASE_URL}profile-pic-remove`,
    SENT_OTP_USER: `${API_BASE_URL}send-otp`,
    SENT_AUTH_OTP: `${API_BASE_URL}send-auth-otp`,
    VERIFY_OPT: `${API_BASE_URL}verify-otp`,
    VERIFY_AUTH_OTP: `${API_BASE_URL}verify-auth-otp`,
    MY_ORDERS: `${API_BASE_URL}my-orders`,
    ORDER_DETAILS: `${API_BASE_URL}order-details`,
    CANCEL_ORDER: `${API_BASE_URL}cancel-order`,
  },
  RESIDENCE: {
    ODATA: `${API_ROOT_URL}/odata`,
    ADD_RESIDENCE: `${API_BASE_URL}`,
    GET_RESIDENCIES: `${API_ROOT_URL}/v1/Entities/Data/RetrieveAPsResidencies`,
    GET_RESIDENCE_AMENITIES: `${API_ROOT_URL}/v1/Entities/Data/GetTypeAmenity`,
    GET_RESIDENCE_ADDITIONAL_INFO: `${API_BASE_URL}/Amenity/lookup`,
    GET_CASE_QUERY_TYPES: `${API_ROOT_URL}/v1/Entities/Data/GetCaseClassifications`,
    GET_PAYMENT_VALUE: `${API_ROOT_URL}/v1/Entities/Data/GetPaymentAmount`,
  },
  CASES: {
    DETAILS: `${API_ROOT_URL}/v1/Entities/Data/RetrieveCaseDetails`,
  },
};

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: "ACTIONS/LOADER/SET_FP_STATUS",
  },
  COMMON: {
    POST: "ACTIONS/COMMON/POST",
    GET: "ACTIONS/COMMON/GET",
    GET_INSTITUTIONS: "ACTIONS/COMMON/GET_INSTITUTIONS",
    GET_CAMPUS: "ACTIONS/COMMON/GET_CAMPUS",
    GET_CAPACITIES: "ACTIONS/COMMON/GET_CAPACITIES",
    RETREIVE: "ACTIONS/COMMON/RETREIVE",
    RETREIVE_INSTITUTION: "ACTIONS/COMMON/RETREIVE_INSTITUTION",
    GET_ROOMS: "ACTIONS/COMMON/GET_ROOMS",
    GET_STUDENT_CASES_CLASSIFICATION:
      "ACTIONS/COMMON/GET_STUDENT_CASES_CLASSIFICATION",
    RETREIVE_CASE_DETAILS: "ACTIONS/COMMON/RETREIVE_CASE_DETAILS",
    RETREIVE_STUDENT_CASE_REGARDINGID:
      "ACTIONS/COMMON/RETREIVE_STUDENT_CASE_REGARDINGID",
    GET_IMPORT_TYPE: "ACTIONS/COMMON/GET_IMPORT_TYPE",
    //
    ACCEPT_TERMS: "ACTIONS/USER/ACCEPT_TERMS",
    GET_NOTIFICATIONS: "ACTIONS/COMMON/GET_NOTIFICATIONS",
    READ_NOTIFICATION: "ACTIONS/COMMON/READ_NOTIFICATION",
  },
  USER: {
    LOGIN: "ACTIONS/USER/LOGIN",
    ME: "ACTIONS/USER/ME",
    LOGOUT: "ACTIONS/USER/LOGOUT",
    REGISTER_ENTITY: "SAGA_ACTIONS/USER/REGISTER_ENTITY",
    REGISTER_INDIVIDUAL: "SAGA_ACTIONS/USER/REGISTER_INDIVIDUAL",
    REGISTER_STUDENT: "SAGA_ACTIONS/USER/REGISTER_STUDENT",

    //
    ACCEPT_TERMS: "ACTIONS/USER/ACCEPT_TERMS",
    GET_NOTIFICATIONS: "ACTIONS/USER/GET_NOTIFICATIONS",
    READ_NOTIFICATION: "ACTIONS/USER/READ_NOTIFICATION",

    SOCIAL_LOGIN: "ACTIONS/USER/SOCIAL_LOGIN",
    INSTAGRAM_DATA: "ACTIONS/USER/INSTAGRAM_DATA",
    FORGOT_PASSWORD: "ACTIONS/USER/FORGOT_PASSWORD",
    RESET_PASSWORD: "ACTIONS/USER/RESET_PASSWORD",
    EDIT_PROFILE: "ACTIONS/USER/EDIT_PROFILE",
    EDIT_PREFERENCE: "ACTIONS/USER/EDIT_PREFERENCE",
    EDIT_USER_SETTINGS: "ACTIONS/USER/EDIT_USER_SETTINGS",
    UPDATE_PROFILE_PIC: "ACTIONS/USER/UPDATE_PROFILE_PIC",
    REMOVE_PROFILE_PIC: "ACTIONS/USER/REMOVE_PROFILE_PIC",
    SENT_OTP_USER: "ACTIONS/USER/SENT_OTP_USER",
    SENT_AUTH_OTP: "ACTIONS/USER/SENT_AUTH_OTP",
    VERIFY_OPT: "ACTIONS/USER/VERIFY_OPT",
    VERIFY_AUTH_OTP: "ACTIONS/USER/VERIFY_AUTH_OTP",
    MY_ORDERS: "ACTIONS/USER/MY_ORDERS",
    ORDER_DETAILS: "ACTIONS/USER/ORDER_DETAILS",
    CANCEL_ORDER: "ACTIONS/USER/CANCEL_ORDER",
  },
  RESIDENCE: {
    GET_RESIDENCE: "ACTIONS/RESIDENCE/GET_RESIDENCE",
    ADD_RESIDENCE: "ACTIONS/RESIDENCE/ADD_RESIDENCE",
    GET_RESIDENCIES: "ACTIONS/RESIDENCE/GET_RESIDENCIES",
    GET_RESIDENCE_ROOMS: "ACTIONS/RESIDENCE/GET_RESIDENCE_ROOMS",
    GET_RESIDENCE_AMENITIES: "ACTIONS/RESIDENCE/GET_RESIDENCE_AMENITIES",
    GET_RESIDENCE_ADDITIONAL_INFO:
      "ACTIONS/RESIDENCE/GET_RESIDENCE_ADDITIONAL_INFO",
    GET_CASE_QUERY_TYPES: "ACTIONS/RESIDENCE/GET_CASE_QUERY_TYPES",
    GET_PAYMENT_VALUE: "ACTIONS/RESIDENCE/GET_PAYMENT_VALUE",
  },
  CASES: {
    DETAILS: "ACTIONS/CASEES/DETAILS",
  },
};

export const SAGA_ACTIONS = {
  COMMON: {
    POST: "SAGA_ACTIONS/COMMON/POST",
    GET: "SAGA_ACTIONS/COMMON/GET",
    GET_INSTITUTIONS: "SAGA_ACTIONS/COMMON/GET_INSTITUTIONS",
    GET_CAMPUS: "SAGA_ACTIONS/COMMON/GET_CAMPUS",
    GET_CAPACITIES: "SAGA_ACTIONS/COMMON/GET_CAPACITIES",
    RETREIVE: "SAGA_ACTIONS/COMMON/RETREIVE",
    RETREIVE_INSTITUTION: "SAGA_ACTIONS/COMMON/RETREIVE_INSTITUTION",
    GET_ROOMS: "SAGA_ACTIONS/COMMON/GET_ROOMS",
    GET_STUDENT_CASES_CLASSIFICATION:
      "SAGA_ACTIONS/COMMON/GET_STUDENT_CASES_CLASSIFICATION",
    RETREIVE_CASE_DETAILS: "SAGA_ACTIONS/COMMON/RETREIVE_CASE_DETAILS",
    RETREIVE_STUDENT_CASE_REGARDINGID:
      "SAGA_ACTIONS/COMMON/RETREIVE_STUDENT_CASE_REGARDINGID",
    GET_IMPORT_TYPE: "SAGA_ACTIONS/COMMON/GET_IMPORT_TYPE",
    GET_NOTIFICATIONS: "SAGA_ACTIONS/COMMON/GET_NOTIFICATIONS",
    //
    GET_CAMPUS_INSTITUTION: "SAGA_ACTIONS/COMMON/GET_CAMPUS_INSTITUTION",
    GET_FACILITY_PRICING: "SAGA_ACTIONS/COMMON/GET_FACILITY_PRICING",
    GET_FACILITY_APPLICATION_DETAILS:
      "SAGA_ACTIONS/COMMON/GET_FACILITY_APPLICATION_DETAILS",
    GET_FACILITY_APPLICATIONS: "SAGA_ACTIONS/COMMON/GET_FACILITY_APPLICATIONS",
    GET_APPLICATION_ROOM_TYPES:
      "SAGA_ACTIONS/COMMON/GET_APPLICATION_ROOM_TYPES",
    GET_AP_OFFER_LETTERS: "SAGA_ACTIONS/COMMON/GET_AP_OFFER_LETTERS",
    GET_INSTITUTION_STUDENTS: "SAGA_ACTIONS/COMMON/GET_INSTITUTION_STUDENTS",
    GET_STUDENT_DETAILS: "SAGA_ACTIONS/COMMON/GET_STUDENT_DETAILS",
    GET_CAMPUS_STATS: "SAGA_ACTIONS/COMMON/GET_CAMPUS_STATS",
    GET_INSTITUTION_TOTALS: "SAGA_ACTIONS/COMMON/GET_INSTITUTION_TOTALS",
    GET_CAMPUS_DETAILS: "SAGA_ACTIONS/COMMON/GET_CAMPUS_DETAILS",
    GET_CAMPUS_PROPERTIES: "SAGA_ACTIONS/COMMON/GET_CAMPUS_PROPERTIES",
    GET_AP_USERS: "SAGA_ACTIONS/COMMON/GET_AP_USERS",
    GET_INDIVIDUAL_AP_USERS: "SAGA_ACTIONS/COMMON/GET_INDIVIDUAL_AP_USERS",
    GET_ROOM_TYPES: "SAGA_ACTIONS/COMMON/GET_ROOM_TYPES",
    GET_APPLICATION_DATES: "SAGA_ACTIONS/COMMON/GET_APPLICATION_DATES",
    EXISTING_STUDENT_CHECK: "SAGA_ACTIONS/COMMON/EXISTING_STUDENT_CHECK",
  },
  USER: {
    LOGIN: "SAGA_ACTIONS/USER/LOGIN",
    LOGOUT: "SAGA_ACTIONS/USER/LOGOUT",
    ME: "SAGA_ACTIONS/USER/ME",
    REGISTER_ENTITY: "SAGA_ACTIONS/USER/REGISTER_ENTITY",
    REGISTER_INDIVIDUAL: "SAGA_ACTIONS/USER/REGISTER_INDIVIDUAL",
    REGISTER_STUDENT: "SAGA_ACTIONS/USER/REGISTER_STUDENT",
    STUDENT_SECURITY_CHECK: "SAGA_ACTIONS/USER/STUDENT_SECURITY_CHECK",

    //
    CONFIRM_OTP: "SAGA_ACTIONS/USER/CONFIRM_OTP",
    GENERATE_OTP: "SAGA_ACTIONS/USER/GENERATE_OTP",
    ACCEPT_TERMS: "SAGA_ACTIONS/USER/ACCEPT_TERMS",

    INSTAGRAM_DATA: "SAGA_ACTIONS/USER/INSTAGRAM_DATA",
    FORGOT_PASSWORD: "SAGA_ACTIONS/USER/FORGOT_PASSWORD",
    RESET_PASSWORD: "SAGA_ACTIONS/USER/RESET_PASSWORD",
    EDIT_PROFILE: "SAGA_ACTIONS/USER/EDIT_PROFILE",
    EDIT_PREFERENCE: "SAGA_ACTIONS/USER/EDIT_PREFERENCE",
    EDIT_USER_SETTINGS: "SAGA_ACTIONS/USER/EDIT_USER_SETTINGS",
    UPDATE_PROFILE_PIC: "SAGA_ACTIONS/USER/UPDATE_PROFILE_PIC",
    REMOVE_PROFILE_PIC: "SAGA_ACTIONS/USER/REMOVE_PROFILE_PIC",
    SENT_OTP_USER: "SAGA_ACTIONS/USER/SENT_OTP_USER",
    SENT_AUTH_OTP: "SAGA_ACTIONS/USER/SENT_AUTH_OTP",
    VERIFY_OPT: "SAGA_ACTIONS/USER/VERIFY_OPT",
    VERIFY_AUTH_OTP: "SAGA_ACTIONS/USER/VERIFY_AUTH_OTP",
    MY_ORDERS: "SAGA_ACTIONS/USER/MY_ORDERS",
    ORDER_DETAILS: "SAGA_ACTIONS/USER/ORDER_DETAILS",
    CANCEL_ORDER: "SAGA_ACTIONS/USER/CANCEL_ORDER",
  },
  RESIDENCE: {
    GET_RESIDENCE: "SAGA_ACTIONS/RESIDENCE/GET_RESIDENCE",
    ADD_RESIDENCE: "SAGA_ACTIONS/RESIDENCE/ADD_RESIDENCE",
    GET_RESIDENCIES: "SAGA_ACTIONS/RESIDENCE/GET_RESIDENCIES",
    GET_RESIDENCE_ROOMS: "SAGA_ACTIONS/RESIDENCE/GET_RESIDENCE_ROOMS",
    GET_RESIDENCE_AMENITIES: "SAGA_ACTIONS/RESIDENCE/GET_RESIDENCE_AMENITIES",
    GET_RESIDENCE_ADDITIONAL_INFO:
      "SAGA_ACTIONS/RESIDENCE/GET_RESIDENCE_ADDITIONAL_INFO",
    GET_CASE_QUERY_TYPES: "SAGA_ACTIONS/RESIDENCE/GET_CASE_QUERY_TYPES",
    GET_PAYMENT_VALUE: "SAGA_ACTIONS/RESIDENCE/GET_PAYMENT_VALUE",
  },
  CASES: {
    DETAILS: "SAGA_ACTIONS/CASES/DETAILS",
  },
};

export const MAX_IMAGE_RESOLUTION = 1280;
export const MAX_IMAGE_UPLOADS = 10;
export const MIN_IMAGE_UPLOADS = 4;
export const PASSWORD_VALIDATION_REGEX = /^(?=\D*\d)(?=.*?[a-zA-Z]).*[\W_]/;
// export const PASSWORD_VALIDATION_REGEX = /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[\W_]).{8,}$/;
export const DIGITS_ONLY_PATTERN = /^[0-9]*$/;
export const DIGITS_AND_DOT_ONLY_PATTERN = /^[0-9]+([.][0-9]+)?$/;

export const INPUT_MAX_LENGTHS = {
  tradingName: 500,
  registeredName: 30,
  registrationNumber: 50,
  vatNumber: 10,
  mobile: 15,
  telephone: 15,
  email: 500,
  firstName: 100,
  lastName: 50,
  idNumber: 50,
  streetNumber: 100,
  streetAddress: 100,
  city: 100,
  suburb: 100,
  postalCode: 100,
  facilityName: 250,
  kmFromCampus: 18,
  address: 150,
  default: 100,
  accountNumber: 50,
  branchNumber: 50,
};
