import {
  InfoOutlined,
  MoreVert,
  TrendingDown,
  TrendingUp,
} from "@mui/icons-material";
import { Box, CardContent, IconButton, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { ReactNode } from "react";
import { ThemeColors } from "../../../styles/theme";
import FeatureBlocks from "../../FeatureBlocks";

function SummaryCard({
  onClick,
  icon,
  label,
  value,
  trend,
}: {
  onClick?: () => void;
  icon?: ReactNode;
  label: string;
  value: number;
  trend: number;
}) {
  return (
    <Card
      onClick={onClick}
      variant="outlined"
      color="primary"
      sx={{ width: "100%", m: 1, cursor: "pointer", borderRadius: 2 }}
    >
      <CardContent>
        <Box display="flex" width="100%">
          <Box flex={1}>
            <FeatureBlocks.Icon icon={icon} />
          </Box>
          <Box>
            <IconButton>
              <MoreVert />
            </IconButton>
          </Box>
        </Box>
        <Box py={2}>
          <Typography sx={{ fontSize: 14, color: ThemeColors.textTertiary }}>
            {label}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography sx={{ fontSize: 36, fontWeight: 600 }}>
              {value}
            </Typography>
          </Box>
          {trend !== 0 && (
            <Box>
              <Typography
                sx={{ fontSize: 14 }}
                color={
                  trend > 0
                    ? ThemeColors.successSecondary
                    : ThemeColors.primaryOrange
                }
              >
                {trend > 0 ? (
                  <TrendingUp color="inherit" />
                ) : (
                  <TrendingDown color="inherit" />
                )}{" "}
                {trend}%{" "}
                <Box component="span" sx={{ color: ThemeColors.textTertiary }}>
                  vs last month
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default SummaryCard;
