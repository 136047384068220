import LegacyPageLayout from "../../../../components/LegacyPageLayout";
import CheckInFlow from "./CheckInFlow";

import VerifyOtpFlow from "./VerifyOtpFlow";

function StudentCheckInDetailsPage() {
  return (
    <LegacyPageLayout padding={4} bgColor="#F8FAFC">
      <CheckInFlow />
    </LegacyPageLayout>
  );
}

export default StudentCheckInDetailsPage;
