import { ContentPaste, InfoOutlined } from "@mui/icons-material";
import { Box, ButtonBase, Input, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useAppLoader } from "../../../../../../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useUserApi } from "../../../../../../_common/hooks/api/UserApiHook";
import useCurrentUser from "../../../../hooks/useCurrentUser";

type Props = {};

export default function OTPPage({}: Props) {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();

  const [searchParams, setSearchParams] = useSearchParams();

  // Get a specific query parameter
  const myParam = searchParams.get("myParam");

  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  const { id: studentId } = useParams();
  const studentEmail = searchParams.get("email");
  const studentNumber = searchParams.get("number");
  const employeeId = searchParams.get("employee-id");

  const currentUser = useCurrentUser();

  const userApi = useUserApi();
  const [isAlternativeNumber, setIsAlternativeNumber] = useState(false);
  const [otp, setOtp] = useState<string>("");

  const maskedNumber = studentNumber
    ? studentNumber.replace(/^(\d{2})\d{6}(\d{2})$/, "$1******$2")
    : null;

  console.log("otp", otp);
  console.log("studentEmail", studentEmail);
  console.log("studentNumber", studentNumber);

  const handleChange = (newValue: string) => {
    setOtp(newValue);
  };

  useEffect(() => {
    if (otp.length === 5) {
      onSubmit({
        email: studentEmail,
        mobileNumber: studentNumber,
        otpCode: otp,
      });
    }
  }, [otp]);

  const sendOtpCode = (email: string, mobileNumber: string) => {
    showLoader();
    userApi.generateOtp(
      {
        requestName: "GenerateOTPReq",
        inputParamters: {
          OTPInformation: {
            Email: email,
            Mobile: mobileNumber,
          },
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        toast.success(resp.clientMessage || message);

        // setStage(2);
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const confirmOtpCode = (
    email: string,
    mobileNumber: string,
    otpNo: string
  ) => {
    showLoader();
    userApi.confirmOtp(
      {
        requestName: "ConfirmOTPReq",
        inputParamters: {
          OTPInformation: {
            OTPNo: otpNo,
            email,
            mobile: mobileNumber,
          },
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        toast.success(resp.clientMessage || message);
        // onCompletePreregistration(email, mobileNumber);

        //redirect
        navigate(`/ap/students/${employeeId}/invite`);
      },
      (message: string) => {
        hideLoader();
        toast.error(message + "...");
      }
    );
  };

  const onSubmit = (data: any) => {
    if (data.otpCode) {
      const { email, mobileNumber, otpCode } = data;
      confirmOtpCode(email, mobileNumber, otpCode);
    } else {
      sendOtpCode(data.email, data.mobileNumber);

      return;
    }
  };

  useEffect(() => {
    setOtp("");
    onSubmit({ email: studentEmail, mobileNumber: studentNumber });
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        maxWidth: "400px",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0px auto ",
        paddingX: 1,
        paddingY: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box
          sx={{
            border: "1px solid #d0d5dd",
            padding: 2,
            borderRadius: 2,
            mb: 3,
          }}
        >
          <ContentPaste />
        </Box>
        <Typography sx={{ fontSize: "30px", fontWeight: "bold", mb: 2 }}>
          Enter verification code
        </Typography>
        <Typography sx={{ textAlign: "center", color: "#949598" }}>
          We sent a verification code to the student on thier number{" "}
          {maskedNumber}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 2, mb: 4, width: "100%" }}>
        <MuiOtpInput value={otp} onChange={handleChange} length={5} />
      </Box>
      <Box sx={{ width: "100%", mb: 4 }}>
        {isAlternativeNumber && (
          <>
            {" "}
            <Typography sx={{ fontWeight: "20px" }} variant="subtitle2">
              Cell Phone
            </Typography>
            <TextField sx={{ width: "100%" }} />
            <Typography variant="subtitle2" sx={{ mb: 2, mt: 1 }}>
              Enter your alternative number
            </Typography>
            <ButtonBase
              sx={{
                width: "100%",
                height: "50px",
                border: "2px solid #d0d5dd",
                borderRadius: 3,
                fontWeight: "bold",
                fontSize: "22px",
              }}
              disabled
            >
              Verify
            </ButtonBase>
          </>
        )}
      </Box>
      <Typography sx={{ display: "flex" }}>
        Didn't recieve the code ?
        <Typography
          sx={{
            color: "#F88A0B",
            fontWeight: "bold",
            ml: 0.5,
            cursor: "pointer",
          }}
          onClick={() => {
            setIsAlternativeNumber(!isAlternativeNumber);
          }}
        >
          Alternative number
        </Typography>
      </Typography>
    </Box>
  );
}
