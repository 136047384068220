import { Box, Typography } from '@mui/material';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import React from 'react'


function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            textAlign="center"
            color="text.secondary"
          >
            <Box sx={{ fontSize: 8, display: "inline" }} component="span">
              Uploading
            </Box>
            <br />
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

export default CircularProgressWithLabel;