import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import illustrationSrc from "./illustration.svg";

export default NiceModal.create(
  ({
    isSelfieDone = false,
    isRoomPictureDone = false,
    onConfirm,
    onCancel,
  }: {
    isSelfieDone?: boolean;
    isRoomPictureDone?: boolean;
    onCancel?: () => void;
    onConfirm: () => void;
  }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const handleConfirm = async () => {
      onConfirm();
      await modal.hide();
      modal.remove();
    };

    const handleCancel = async (e: unknown, reason?: string) => {
      if (reason && reason === "backdropClick") {
        return;
      }
      onCancel?.();
      await modal.hide();
      modal.remove();
    };
    if(!modal.visible) return null;
    return (
      <Dialog open={modal.visible} onClose={handleCancel} fullScreen>
        <DialogTitle sx={{ display: "flex", alignItems: "left" }}>
          <IconButton onClick={handleCancel}>
            <Close />
          </IconButton>
          <Box sx={{ flex: 1, textAlign: "left" }}>Check-In</Box>
          <Box></Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            <img alt="" src={illustrationSrc} />
          </Box>
          <Typography sx={{my:3}}>
            This should take around 1-3 mins, you’ll need:
          </Typography>
          <Box sx={{my:1}}>
            <FormControlLabel
              control={<Checkbox checked={isSelfieDone} />}
              label="To take a selfie using your phone"
            />
          </Box>
          <Box sx={{my:1}}>
            <FormControlLabel
              control={<Checkbox checked={isRoomPictureDone} />}
              label="To take some pictures of the room you’ll be staying in"
            />
          </Box>
          <Button onClick={handleConfirm} sx={{ flex: 1, my:3, }} fullWidth variant="contained">
            Next
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
);
