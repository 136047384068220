import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { URLS } from "./_config";

import PrivateRoutes from "./_config/privateRoutes";
import OpenRoutes from "./_config/openRoutes";

import Registration from "./pages/registration";
import EntryType from "./pages/entryType";

import Dashboard from "./pages/dashboard";
import AddResidence from "../src/pages/addResidence";
import AddRoom from "./pages/addRoom";
import ResidenceSummery from "./pages/residenceSummery";
import DescribeRooms from "./pages/describeRooms";
import MoreAboutProperty from "./pages/moreAboutProperty";
import UploadPropertyImages from "./pages/uploadPropertyImages";
import UploadPropertyDocuments from "./pages/uploadPropertyDocuments";
import PaymentInfo from "./pages/paymentInfo";
import Payment from "./pages/payment";
import CreateCase from "./pages/createCase";
import SuccessPage from "./pages/successsPage";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.scss";
import CancelPage from "./pages/cancelPage";
import NotifyPage from "./pages/notifyPage";
import MakePayment from "./pages/makePayment";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import RegistrationSuccessful from "./pages/registrationSuccessful";
import MainDashboard from "./pages/mainDashboard";
import OpenCases from "./pages/openCases";
import Leases from "./pages/leases";

import StudentWeb from "./pages/student";

import ResidenceDetails from "./pages/residenceDetails";
import OpenCaseDetails from "./pages/openCaseDetails";
import Tenants from "./pages/tenants";
import TenantsDetails from "./pages/tenantsDetails";
import Institution from "./pages/institution";
import InstitutionUpload from "./pages/institutionUpload";
import InstitutionView from "./pages/institutionView";
import InstitutionUploadList from "./pages/institutionUploadList";
import InstitutionUploadStatus from "./pages/institutionUploadStatus";
import InstitutionViewList from "./pages/institutionViewList";
import StudentRegistration from "./pages/student/studentRegistration";
import UpdateProfile from "./pages/updateProfile";
import PageNotFound from "./pages/pageNotFound";
// import Test from './pages/test'
import TestPage from "./pages/testPage";
import MyProfile from "./pages/student/myProfile";
import RedirectPage from "./pages/redirectPage";
import ProceedLogin from "./pages/proceedLogin";
import ResidenceApplications from "./pages/residenceApplications";
import Residences from "./pages/residences";
import OfferLetter from "./pages/offerLetter";
import WorkSpace from "./pages/workSpace";
import LoginPage from "./ui/v2/pages/Login";
import Login from "./pages/login";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "./ui/v2/styles/theme";
import PaymentOptions from "./pages/paymentOptions";
import ResetPasswordSuccess from "./pages/resetPasswordSuccess";
import PaymentOptionGate from "./pages/paymentOptionGate";
import StudentList from "./pages/studentList";
import StudentViewAction from "./pages/studentViewAction";
import InstitutionDashboard from "./pages/institutionDashboard";
import CampusDetails from "./pages/campusDetails";
import UserList from "./pages/userList";
import UserDetails from "./pages/userDetails";
import CreateUser from "./pages/createUser";
import AddCampus from "./pages/addCampus";
import ViewProperties from "./pages/viewProperties";
import AddStudent from "./pages/addStudent";
import MyProperty from "./pages/student/myProperty";
import StudentReg from "./pages/studentRegistration";
import StudentRegistrationSuccessful from "./pages/studentRegistrationSuccessful";
import Invoices from "./pages/invoices";
import { Worker } from "@react-pdf-viewer/core";
import Cases from "./pages/cases";
import CreateCase_TEMPORARY from "./pages/createCase_TEMPORARY";
import CaseDetails_TEMPORARY from "./pages/caseDetails_TEMPORARY";
import StudentCheckInListingPage from "./ui/v2/pages/StudentCheckInListingPage";
import StudentCheckInDetailsPage from "./ui/v2/pages/StudentCheckInDetailsPage";
import TenantManagement from "./ui/v2/pages/AP/TenantManagement";
import NiceModal from "@ebay/nice-modal-react";
import StudentResidenceListingPage from "./ui/v2/pages/StudentResidenceListingPage";
import EditProfile from "./ui/v2/pages/EditProfile";
import StudentSearch from "./ui/v2/pages/AP/StudentSearch";
import ViewStudentProfilePage from "./ui/v2/pages/AP/ViewStudentProfile";
import SecurityQuestions from "./ui/v2/pages/AP/StudentSearch/SecurityQuestions";
import OTPPage from "./ui/v2/pages/AP/StudentSearch/OTP";
import InviteStudent from "./ui/v2/pages/AP/StudentSearch/InviteStudent";

function App() {
  return (
    <ThemeProvider theme={theme}>
      
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Router>
          <NiceModal.Provider>
            <Routes>
              <Route element={<OpenRoutes />}>
                <Route path={URLS.ENTRY_TYPE} element={<EntryType />} />
                <Route path={URLS.REGISTER_STUDENT} element={<StudentReg />} />

                {/* <Route path={URLS.LOGIN} element={<LoginPage />} /> */}
                <Route path={URLS.LOGIN} element={<Login />} />
                <Route path={URLS.REGISTRATION} element={<Registration />} />
                <Route
                  path={URLS.FORGOT_PASSWORD}
                  element={<ForgotPassword />}
                />
                <Route
                  path={URLS.REGISTRATION_SUCCESSFUL}
                  element={<RegistrationSuccessful />}
                />
                <Route
                  path={URLS.PASSWORD_RESET_SUCCESSFUL}
                  element={<ResetPasswordSuccess />}
                />
                {/* <Route path={URLS.STUDENT_REGISTRATION} element={<StudentRegistration />} /> */}
                <Route path={URLS.REDIRECT_PAGE} element={<RedirectPage />} />
                <Route
                  path={URLS.SUCCESSFUL_STUDENT_REGISTRATION}
                  element={<StudentRegistrationSuccessful />}
                />

                {/* <Route path={URLS.TEST_PAGE} element={<TestPage/>} /> */}
                {/* <Route path={URLS.SECURITY_PAGE} element={<securityPage/>}/> */}

                {/* <Route path={URLS.UPDATE_DETAILS} element={<updateRegDetails/>} /> */}
                <Route path={URLS.WORK_SPACE} element={<WorkSpace />} />
              </Route>
              <Route path={URLS.SUCCESS_PAGE} element={<SuccessPage />} />
              <Route path={URLS.CANCEL_PAGE} element={<CancelPage />} />
              <Route path={URLS.NOTIFY_PAGE} element={<NotifyPage />} />
              <Route path={URLS.MAKE_PAYMENT} element={<MakePayment />} />

              <Route element={<PrivateRoutes />}>
                <Route
                  path={URLS.INSTITUTION_DASHBOARD}
                  element={<InstitutionDashboard />}
                />
                <Route
                  path={URLS.STUDENT_LISTING_VIEW}
                  element={<StudentList />}
                />
                <Route
                  path={URLS.STUDENT_ACTION_VIEW}
                  element={<StudentViewAction />}
                />
                {/* <Route path={URLS.CAMPUS_DETAILS} element={<CampusDetails />} /> */}
                <Route
                  path={URLS.VIEW_CAMPUS_DETAILS}
                  element={<CampusDetails />}
                />
                <Route path={URLS.USER_LIST} element={<UserList />} />
                {/* <Route path={URLS.USER_DETAILS} element={<UserDetails />} /> */}
                <Route
                  path={URLS.VIEW_USER_DETAILS}
                  element={<UserDetails />}
                />
                <Route path={URLS.CREATE_USER} element={<CreateUser />} />
                <Route path={URLS.ADD_CAMPUS} element={<AddCampus />} />
                {/* <Route path={URLS.ADD_CAMPUS} element={</>} /> */}
                <Route
                  path={URLS.VIEW_PROPERTIES}
                  element={<ViewProperties />}
                />
                <Route path={URLS.ADD_STUDENT} element={<AddStudent />} />
                <Route
                  path={URLS.STUDENT.MY_PROPERTY}
                  element={<MyProperty />}
                />
                <Route
                  path={URLS.STUDENT.CHECK_IN_LISTING}
                  element={<StudentCheckInListingPage />}
                />
                <Route
                  path={URLS.STUDENT.CHECK_IN_DETAILS}
                  element={<StudentCheckInDetailsPage />}
                />
                <Route
                  path={URLS.STUDENT.RESIDENCE_LISTING}
                  element={<StudentResidenceListingPage />}
                />

                {/* <Route path={URLS.TEST_PAGE} element={<TestPage/>} /> */}
                <Route path={URLS.RESET_PASSWORD} element={<ResetPassword />} />
                <Route
                  path={URLS.PAYMENT_OPTIONS}
                  element={<PaymentOptions />}
                />
                <Route
                  path={URLS.PAYMENT_OPTIONS_GATE}
                  element={<PaymentOptionGate />}
                />

                <Route path={URLS.DASHBOARD} element={<Dashboard />} />
                <Route path={URLS.TEST_PAGE} element={<TestPage />} />
                <Route
                  path={URLS.PROCEED_TO_LOGIN}
                  element={<ProceedLogin />}
                />
                <Route path={URLS.DASHBOARD} element={<Dashboard />} />
                <Route path={URLS.TEST_PAGE} element={<TestPage />} />
                <Route
                  path={URLS.PROCEED_TO_LOGIN}
                  element={<ProceedLogin />}
                />

                <Route path={URLS.ADD_RESIDENCE} element={<AddResidence />} />
                <Route path={URLS.ADD_ROOM} element={<AddRoom />} />
                <Route
                  path={URLS.RESIDENCE_SUMMERY}
                  element={<ResidenceSummery />}
                />
                <Route path={URLS.DESCRIBE_ROOMS} element={<DescribeRooms />} />
                <Route
                  path={URLS.MORE_ABOUT_PROPERTY}
                  element={<MoreAboutProperty />}
                />
                <Route
                  path={URLS.UPLOAD_PROPERTY_IMAGES}
                  element={<UploadPropertyImages />}
                />
                <Route
                  path={URLS.UPLOAD_PROPERTY_DOCUMENTS}
                  element={<UploadPropertyDocuments />}
                />
                <Route path={URLS.PAYMENT_INFO} element={<PaymentInfo />} />
                <Route path={URLS.PAYMENT} element={<Payment />} />
                <Route path={URLS.CREATE_CASE} element={<CreateCase />} />
                {/* <Route path={URLS.UPDATE_PROFILE} element={<UpdateProfileBkp />} /> */}
                <Route path={URLS.UPDATE_PROFILE} element={<UpdateProfile />} />
                <Route path={URLS.HOME} element={<MainDashboard />} />
                <Route path={URLS.OPEN_CASES} element={<OpenCases />} />
                <Route
                  path={URLS.OPEN_CASE_DETAILS}
                  element={<OpenCaseDetails />}
                />
                <Route path={URLS.LEASES} element={<Leases />} />
                <Route path={URLS.STUDENT.ROOT} element={<StudentWeb />} />
                <Route
                  path={URLS.RESIDENCE_DETAILS}
                  element={<ResidenceDetails />}
                />
                <Route path={URLS.TENANTS} element={<Tenants />} />
                <Route
                  path={URLS.TENANTS_DETAILS}
                  element={<TenantsDetails />}
                />
                <Route path={URLS.INSTITUTION} element={<Institution />} />
                <Route
                  path={URLS.INSTITUTION_UPLOAD}
                  element={<InstitutionUpload />}
                />
                <Route
                  path={URLS.INSTITUTION_VIEW}
                  element={<InstitutionView />}
                />
                <Route
                  path={URLS.INSTITUTION_VIEW_LIST}
                  element={<InstitutionViewList />}
                />
                <Route
                  path={URLS.INSTITUTION_UPLOAD_STATUS}
                  element={<InstitutionUploadStatus />}
                />
                <Route
                  path={URLS.INSTITUTION_UPLOAD_LIST}
                  element={<InstitutionUploadList />}
                />
                <Route
                  path={`${URLS.UPDATE_PROFILE}/:profileSection`}
                  element={<UpdateProfile />}
                />
                {/* <Route path={`${URLS.UPDATE_PROFILE}/:profileSection`} element={<UpdateProfile />} /> */}
                <Route path={URLS.STUDENT.MY_PROFILE} element={<MyProfile />} />
                <Route
                  path={URLS.RESIDENCE_APPLICATIONS}
                  element={<ResidenceApplications />}
                />
                <Route path={URLS.RESIDENCES} element={<Residences />} />
                <Route path={URLS.INVOICES} element={<Invoices />} />
                <Route path={URLS.CASES} element={<Cases />} />
                <Route
                  path={URLS.CREATE_CASE_TEMPORARY}
                  element={<CreateCase_TEMPORARY />}
                />
                <Route
                  path={URLS.CASES_DETAILS}
                  element={<CaseDetails_TEMPORARY />}
                />
                <Route path={URLS.OFFER_LETTER} element={<OfferLetter />} />
                <Route path={URLS.AP.TENANTS} element={<TenantManagement />} />
                <Route path={URLS.AP.EDIT_PROFILE} element={<EditProfile />} />
                <Route
                  path={URLS.AP.STUDENT_SEARCH}
                  element={<StudentSearch />}
                />
                <Route
                  path={URLS.AP.STUDENT_DETAILS}
                  element={<ViewStudentProfilePage />}
                />
                <Route
                  path={URLS.AP.SECURITY_QUESTIONS}
                  element={<SecurityQuestions />}
                />
                <Route path={URLS.AP.OTP} element={<OTPPage />} />
                <Route
                  path={URLS.AP.INVITE_STUDENT}
                  element={<InviteStudent />}
                />
                <Route
                  path={URLS.STUDENT.EDIT_PROFILE}
                  element={<EditProfile />}
                />
                <Route path="*" element={<PageNotFound />} />

              </Route>
            </Routes>
            </NiceModal.Provider>
          </Router>
        </Worker>
      
    </ThemeProvider>
  );
}

export default App;
