import React from "react";
import HeaderText from "../../GuestPageLayout/HeaderText";
import Typography from "@mui/material/Typography";
import { BreadcrumbData } from "../../Breadcrumbs/types";
import Breadcrumbs from "../../Breadcrumbs";
import Box from "@mui/material/Box";
import { Divider, Grid } from "@mui/material";
import { URLS } from "../../../../../_config";

type Props = {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  breadcrumbData?: BreadcrumbData;
  actionButton?: React.ReactNode;
  padding?: "md" | "lg";
};

const BREADCRUMB_DATA: BreadcrumbData = [
  {
    label: "Dashboard",
    path: URLS.DASHBOARD,
  },
];

/** Page content wrapper for v2 ui */
function ContentPage({
  title,
  subtitle,
  children,
  breadcrumbData,
  actionButton,
  padding = "md",
}: Props) {
  const hasAction = !!actionButton;
  const _padding =
    padding === "md"
      ? { pt: 2, pl: 2, pr: 4 }
      : { pt: { xs: 2 }, pl: { xs: 4, md: 12 }, pr: { xs: 4, md: 12 } };
  return (
    <Box sx={{ width: "100%", ..._padding, pb: 6 }}>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs data={breadcrumbData || BREADCRUMB_DATA} />
        </Grid>
        <Grid item xs={12} md={hasAction ? 10 : 12}>
          {title && <HeaderText>{title}</HeaderText>}
          {subtitle && <Typography fontSize={16}>{subtitle}</Typography>}
        </Grid>
        {hasAction && (
          <Grid item xs={12} md={2}>
            {actionButton}
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContentPage;
